import React from 'react';


const Footer = () => {

    return (
        <>
        <div id="rowStyle" className='row'>
            <div className='col-md-12 footer'>
                <footer>
                    <p> All content ©Eva Cassetta | site by Amaka Anikwe</p>
                </footer>
            </div>

        </div>

        </>
    )

}

export default Footer;
import React from 'react';
import { Link } from 'react-router-dom';

import projData from '../../data/data.json'
import PageHeaderComp from './PageHeaderComp.jsx';
import Footer from './Footer.jsx'

const PageTemp = ({index, match}) => {
    const pageId = match.params.id;
    const data = projData.find(data => data.title === pageId);
 

return (
    <> 
        <section className="paddingStyle">
            <div className="container-fluid containerStyle">
               
                    < PageHeaderComp />
                
            
                <div className='row'>
                    <div className="col-lg-8 pageTempImg pageProjectStyle">
                        {data.pageImgs.map((index, i) => <img className = 'img-responsive imgStyle'src={index} />)}
                    </div>
                    <div className="col-lg-4">
                        <h3 className="pageTempTitleBorder mainTitleFont"> {pageId} </h3>
                        <p> {data.tags} </p>
                        <br></br>
                        {data.summary.map((index, i) => <p key= {i}>{index}</p> )}
                    </div>
                </div>

                <div className='row'>
                    <div className="col-lg-5">
                        
                        
                    </div>
                    <div className="col-lg-7">
                        <Link to='/' >
                            <button type="button" class="btn btnStyle">| Back to Gallery |</button>
                        </Link>
                    </div>
                </div>
                <Footer />
            </div>
            
        </section>
            
        
    </>
)
    
}

export default PageTemp;



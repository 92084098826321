import React from 'react';
import { Link } from 'react-router-dom';

const PageHeaderComp = () => {

    return (
        <>
            <div className='row headerStyle pageHeaderSty'>
                <div className='col-md-9'>
                <Link to='/' className="titleStyle" >
                <header className="mainHeaderStyle">
                        <h1 className="mainTitleFont"> EVA CASSETTA</h1>
                    </header>
                </Link>
                  
</div>
<div className='col-md-3'>
<Link to='/contact' className="contactLinkStyle" ><h3 >CONTACT</h3></Link>
</div>
</div>

        </>
    )

}

export default PageHeaderComp;
import React from 'react';

import data from '../../data/data';

import MainHeaderComp from './MainHeaderComp.jsx';
import PageIndex from './PageIndex.jsx';
import Footer from './Footer.jsx'


// Function for gallery wall
// Splits data array into object of 3 arrays of equal or lesser size
const imgSpliter = (datafiles) => {

        // Return object set up
        const bank = {
            imgArr1: [],
            imgArr2: [],
            imgArr3: []
        }
    
    
        for (let i = 0; i < datafiles.length; i++) {

            let dataObj = datafiles[i]
            let imgNum =  Math.floor(datafiles.length/3)

            if (bank.imgArr1.length < imgNum) {
                bank.imgArr1.push(dataObj)
    
            } else if (bank.imgArr2.length < imgNum) {
                bank.imgArr2.push(dataObj)
            } else {
                bank.imgArr3.push(dataObj)
            }
                
        }
    
        return bank;
}
    
const imgX3 = imgSpliter(data) 

const PortfolioComp = () => {
  
    return (
        <>
            <section className="paddingStyle">
                <div className="container-fluid containerStyle">

                    <MainHeaderComp />
                    <PageIndex data = {data}  imgX3 = {imgX3} />
                    <Footer />
                </div>
            </section>
            
        </>
    )
        

}

export default PortfolioComp;
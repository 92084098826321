import React from 'react';
import ReactDOM from 'react-dom';  
import App from './App.js';
import reportWebVitals from './reportWebVitals';

// import {createStore} from 'redux';
// import { Provider } from 'react-redux';
// import reducers from './redux/reducers';

// const store = createStore(reducers);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


// ReactDOM.render(<Provider store={store} ><App /></Provider>, document.getElementById('root'));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { Link } from 'react-router-dom';

import LinkedHeaderComp from './LinkedHeaderComp.jsx';
import Footer from './Footer.jsx'

const ContactComp = () => {

    return (
        <>
        <section className="container-fluid contactBodyStyle">
            <div className='row'>
                <div className='col-md-12 headerStyle pageHeaderSty'>   
                   <LinkedHeaderComp />
                </div>
                <div className="col-md-2"></div>
                <div className=" col-md-8 contContactStyle">
                    <div className='contactStyle contactStyleSpace'>
                        <h3>Contact Me</h3>
                    </div>
                    <div className="contactParStyle">
                        <p>Please contact me for my résumé or to see more specific examples of work.</p>
                        <p>You can reach me at cassetta.eva@gmail.com.</p>
                    </div>
                </div>
                <div className="col-md-2"></div>


                <div className="col-md-12 contactStyle">
                        <Link to='/' >
                            <button type="button" class="btn btnStyle">| Back to Gallery |</button>
                        </Link>
                    </div>
            </div>

            <Footer id="rowStyle" />
        </section>


        </>
    )

}

export default ContactComp;
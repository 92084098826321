import React from 'react';
import { Link } from 'react-router-dom';

const MainHeaderComp = () => {

    return (
        <>
        <div className='row headerStyle'>
            <div className='col-md-9'>
                <header className="mainHeaderStyle">
                    <h1 className="mainTitleFont"> EVA CASSETTA</h1>
                    <h1 className="mainTitleFont"> GRAPHIC DESIGNER</h1>
                    <p>branding | packaging | print production | presentation + pitch deck | publication | studio artist</p>
                </header>
            </div>
            <div className='col-md-3'>
                <Link to='/contact' className="contactLinkStyle"><h3>CONTACT</h3></Link>
                
            </div>

        </div>

        </>
    )

}

export default MainHeaderComp;
import React from 'react';
import {BrowserRouter as Router, Route,} from 'react-router-dom';

import './App.css';


import PortfolioComp from './react/components/PortfolioComp.jsx';
import PageTemp from './react/components/PageTemp.jsx'
import ContactComp from './react/components/ContactComp.jsx'
import PageIndex from './react/components/ContactComp.jsx'

const App = () => {

    return (
        <Router>
            <>
                <Route path='/' component={PortfolioComp} exact/>
                <Route path='/project/:id' component={PageTemp} />
                <Route path='/contact' component={ContactComp} />
            </>
        </Router>

    )

}

export default App;
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Hover effect for each column
const ImgBank1 = styled.div`
border-color: black;
    &:hover {
        border-color: ${(props) => (props.firstSet ? props.firstSet : 'black')};
    }
`;

const ImgBank2 = styled.div`
border-color: black;
    &:hover {
        border-color: ${(props) => (props.secondSet ? props.secondSet : 'black')}; 
    }
`;

const ImgBank3 = styled.div`
border-color: black;
    &:hover {
        border-color: ${(props) => (props.thirdSet ? props.thirdSet : 'black')}; 
    }
`;


const PageIndex = ({imgX3}) => {


return (
    <>
        <div className='row'>
            <div className="column"> 
                {imgX3.imgArr1.map((index, i) => <Link to={`/project/${index.title}`} key = {i} index1 ={index}><ImgBank1 className='pageIndexImage' firstSet = {index.borderColor} ><img className='img-responsive' src={index.image} alt='project' /></ImgBank1></Link>)}
             </div>
             <div className="column">
                {imgX3.imgArr2.map((index, i) => <Link to={`/project/${index.title}`} key = {i} ><ImgBank2 className='pageIndexImage' secondSet = {index.borderColor} ><img className='img-responsive' src={index.image} alt='project' /></ImgBank2></Link>)}
             </div>
             <div className="column">
                {imgX3.imgArr3.map((index, i) => <Link  to={`/project/${index.title}`} key = {i}><ImgBank3 className='pageIndexImage' thirdSet = {index.borderColor} ><img className='img-responsive' src={index.image} alt='project' /></ImgBank3></Link>)}
             </div>

        </div>
    </>
)
    
}

export default PageIndex;
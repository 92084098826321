import React from 'react';
import { Link } from 'react-router-dom';

const LinkedHeaderComp = () => {

    return (
            <>
                <header className="mainHeaderStyle">
                    <Link to='/'  className="titleStyle">
                        <h1 className="mainTitleFont"> EVA CASSETTA</h1>
                        <h1 className="mainTitleFont"> GRAPHIC DESIGNER</h1>
                    </Link>
                                    
                        <p>branding | packaging | print production | presentation + pitch deck | publication | studio artist</p>
                </header> 
            </>
    )

}

export default LinkedHeaderComp;